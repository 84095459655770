<template>
  <div class='py-2 lg:py-8 px-4 lg:px-0'>
    <div class='flex flex-col lg:flex-row justify-start items-start'>
      <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/3'>
        <span class='text-lg lg:text-xl'>COVID19 Update</span><br>
        Quarantine Conditions for<br>
        Inbound Travelers in Korea
      </h2>
      <div class='w-full lg:w-2/3 pl-6 lg:pl-0'>
        <ul class='whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
          <li class='mt-2 mb-8'>
            Entrants fully vaccinated will be exempted from quarantine from March 21 if their vaccine status is already registered in Korea.
          </li>
          <li class='mt-2 mb-8'>
            Quarantine Exemption: If you are vaccinated, from March 21, entrants who are fully vaccinated will be exempted from quarantine when they arrive in Korea, if their vaccine status is already registered in Korea.
          </li>
          <li class='mt-2 mb-8 underline'>
            Those who have been vaccinated abroad and did not register their vaccine status in Korea will be exempted from April 1.
          </li>
          <li class='mt-2 mb-8'>
            A person is fully vaccinated if they got their 2nd shot (1st for Jansen) within 180 days and or if they received their 3rd shot. (no time limit after the booster shot) However, entrants from Pakistan, Uzbekistan, Ukraine, and Myanmar are excluded from quarantine exemption even if they are fully vaccinated.  
          </li>
        </ul>
      </div>
    </div>
    <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
      <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/3'>Visa & Passport</h2>
      <div class='w-full lg:w-2/3 pl-6 lg:pl-0'>
        <ul class='whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
          <li class='mt-2 mb-8'>
            All visitors to enter the Republic of Korea must have a valid passport and visa. Visitors with roundtrip tickets from countries who have a special agreement with Korea may be exempt from the visa requirement, and can stay in Korea visa-free for up to 30 days, or 90 days, depending on the type of agreement between two countries. Please contact the Korean embassy or a consulate in your country as early as possible.
          </li>
          <li class='mt-2 mb-8'>
            For more information: <a href='https://www.mofa.go.kr/eng/index.do' class='underline' target='_blank'>www.mofat.go.kr/ENG/visa/application/index.jsp</a>
          </li>
        </ul>
      </div>
    </div>
    <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
      <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/3'>Weather</h2>
      <div class='mt-2 w-full lg:w-2/3 pl-6 lg:pl-0 flex flex-col gap-y-4 lg:gap-y-8'>
        <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/about-korea/weather.png' class='w-full'>
      </div>
    </div>
    <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
      <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/3'>Currency & Banking</h2>
      <div class='w-full lg:w-2/3 pl-6 lg:pl-0'>
        <ul class='whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
          <li class='mt-2 mb-8'>
            The unit of currency is the Korean Won (Indicated as ￦). Coin denominations are ￦10, ￦50, ￦100 and ￦500. Bank notes are ￦1,000, ￦5,000, ￦10,000 and ￦50,000. Foreign bank notes and traveler's checks can be converted into Korean Won at foreign exchange banks and other authorized money exchangers. International credit cards, such as Visa, Master Card, American Express, Diners Club, and JCB are widely accepted at major hotels, department stores and restaurants. US$1 is equivalent to approximately ￦1,152 as of March 2012.
          </li>
        </ul>
        <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/about-korea/currency.png' class='w-full'>
      </div>
    </div>
    <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
      <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/3'>Electricity</h2>
      <div class='w-full lg:w-2/3 pl-6 lg:pl-0'>
        <ul class='whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
          <li class='mt-2 mb-8'>
            The standard electricity supply is 220 volts AC/60 cycles. Most hotels may provide outlet converters for 110 and 220 volts. Participants are advised to check with the hotel beforehand.
          </li>
        </ul>
      </div>
    </div>
    <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
      <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/3'>Time Difference</h2>
      <div class='w-full lg:w-2/3 pl-6 lg:pl-0'>
        <ul class='whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
          <li class='mt-2 mb-8'>
            When Indicating Korea Time: Present Time (GMT+9) / No daylight savings time
          </li>
        </ul>
      </div>
    </div>
    <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
      <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/3'>Business Hours</h2>
      <div class='w-full lg:w-2/3 pl-6 lg:pl-0'>
        <ul class='whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
          <li class='mt-2 mb-8'>
            Government office hours are usually from 9:00 to 18:00 on weekdays and closed on weekends. Banks are open from 9:00 to 16:00 on weekdays and closed on Saturday and Sunday. Most stores are open every day from 10:30 to 20:00, including Sunday.
          </li>
        </ul>
      </div>
    </div>
    <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
      <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/3'>Tax & Tipping</h2>
      <div class='w-full lg:w-2/3 pl-6 lg:pl-0'>
        <ul class='whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
          <li class='mt-2 mb-8'>
            Value-added tax is levied on most goods and services at a standard rate of 10% and is included in the retail price. Tipping is not customary in Korea. Sometimes, expensive restaurants and luxury hotels may add a service charge of 10%. Thus, you do not necessarily have to prepare for extra charges since it will be included in the bill.
          </li>
        </ul>
      </div>
    </div>
    <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
      <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/3'>Emergency Call</h2>
      <div class='w-full lg:w-2/3 pl-6 lg:pl-0'>
        <ul class='list-disc list-inside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
          <li class='mt-2 mb-8'>
            1339 : Medical Emergency
          </li>
          <li class='mt-2 mb-8'>
            119 : Emergencies for Fire / Rescue & Hospital Services
          </li>
          <li class='mt-2 mb-8'>
            112 : Police
          </li>
          <li class='mt-2 mb-8'>
            129 : First Aid Patients
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AboutKorea',
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
    ]),
  }
}
</script>
